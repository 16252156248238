<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Pesanan</b> Toko Online</div>
     
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->

            <div class="row">
              <div class="col-md-6">
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('days')"  v-bind:class="{'btn-info' : btnActive.days, 'btn-outline-info' : btnActive.days == false   }">Hari Ini</button>
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('month')"  v-bind:class="{'btn-info' : btnActive.month, 'btn-outline-info' : btnActive.month == false  }">Bulan Ini</button>
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('lastMonth')"  v-bind:class="{'btn-info' : btnActive.lastMonth, 'btn-outline-info' : btnActive.lastMonth == false  }">Bulan Kemarin</button>
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('year')"  v-bind:class="{'btn-info' : btnActive.year, 'btn-outline-info' : btnActive.year == false  }">Tahun Ini</button>
                <button class="btn mx-1 btn-timeseries "  v-bind:class="{'btn-info' : btnActive.period, 'btn-outline-info' : btnActive.period == false  }" @click="btnPeriodeOnClick">Periode</button>
              </div>
            </div>

            <div class="row justify-content-between">
              <div class="col-md-7" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <template #prepend>
                      <b-button
                        squared
                        @click="resetFilterPeriode"
                        variant="danger"
                        >Reset</b-button
                      >
                      <b-button
                        squared
                        @click="filterByPeriode"
                        variant="success"
                        >Cari</b-button
                      >
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <b-button
                            squared
                            @click="resetFilterPeriode"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="filterByPeriode"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

              

              <b-table striped hover class="mt-3"  :items="itemEmpty" :fields="fields" v-if="status == false">
              
              </b-table>

              <b-table striped hover class="mt-3"  :items="items" :fields="fields" v-if="status">
              <template #cell(transaction_id)="data" v-if="status">
                <!-- {{data.item.transaction_id +" - "+ data.item["customer_name"]}}   -->
                {{data.item.order_id + " - "+ data.item["customer_name"]}}  
                <!-- {{data.item}} -->
              </template>
              

              <template #cell(product_list)="data" v-if="status">
                <div
                  v-for="(product, index) in data.item.product"
                  :key="index"
                >
                  <!-- {{data.item.product[index]}} -->

                  <li>
                    {{ data.item.product[index].name}}
                    <b>{{` (${data.item.product[index].quantity})`}}</b> 
                  </li>
                </div>
              </template>

              <template #cell(total_amount)="data" v-if="status">
                {{"Rp "+parseInt(data.item.total_sales).toLocaleString('id-ID')}}  
              </template>  
              
              <template #cell(date)="data" v-if="status">
                {{data.item.display_date}}  
              </template>   
              
              <template #cell(number_display)="data" v-if="status">
                {{data.item.number}}  
              </template>   

              <!-- <template #cell(order_status)="data" v-if="status">
                <i class="fas fa-check-circle text-success mr-2" v-if="data.item.order_status == 'true'"></i>
                <i class="fas fa-circle text-danger mr-2" v-if="data.item.order_status == 'false'"></i>
                {{data.item.order_status == "true" ? "Lunas":"Menunggu Pembayaran"}}  
              </template> -->
              
              <template #cell(status)="data" v-if="status">
                <i class="fas fa-circle text-primary mr-2" v-if="data.item['current_status'] == 'Diproses'"></i>
                <!-- <i class="fas fa-circle"></i> -->
                <i class="fas fa-circle text-warning mr-2" v-if="data.item['current_status'] == 'Menunggu Pembayaran'"></i>
                <i class="fas fa-circle text-info mr-2" v-if="data.item['current_status'] == 'Sedang Dikirim'"></i>
                <i class="fas fa-circle text-danger mr-2" v-if="data.item['current_status'] == 'Gagal'"></i>
                <i class="fas fa-check-circle text-success mr-2" v-if="data.item['current_status'] == 'Selesai'"></i>
                <i class="fas fa-times-circle text-danger mr-2" v-if="data.item['current_status'] == 'Batal'"></i>
                <i class="fas fa-circle text-primary mr-2" v-if="data.item['current_status'] == 'Terbayar'"></i>
                {{data.item['current_status']}}
              </template>  
             
              <template #cell(actions)="data" v-if="status">
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-12">
                    <div class="d-flex justify-content-center">
                       <b-button
                        size="sm"
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id,'on-hold') "
                        >Menunggu Pembayaran</b-button
                      >

                      <b-button
                        size="sm"
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id, 'processing')"
                        >Diproses</b-button
                      >
                     
                     <b-button
                        size="sm"
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id, 'shipping')"
                        >Sedang Dikirim</b-button
                      >

                      <b-button
                        size="sm" 
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id, 'completed')"
                        >Selesai</b-button
                      >
                      
                  </div>
                  </div>
                   <div class="col-md-12 col-lg-12 col-12">
                    <div class="d-flex justify-content-center">
                      <!-- <b-button
                        size="sm"
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id), statusData = 'refunded'"
                        >Dikembalikan</b-button
                      > -->
                      <b-button
                        size="sm" 
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id, 'paid')"
                        >Terbayar</b-button
                      >
                      <b-button
                        size="sm" 
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id, 'cancelled')"
                        >Batal</b-button
                      >
                      <b-button
                        size="sm"
                        class="mr-1 btn-success mb-1"
                        v-if="system_online_shop"
                        @click="handleEdit(data.item.order_id, 'failed')"
                        >Gagal</b-button
                      >
                      <b-button
                        size="sm" 
                        class="mr-1 btn-info mb-1"
                        @click="$router.push({ path: '/order/detail/'+ data.item.order_id })"  
                        >Detail</b-button
                      >
                    </div>
                   </div>
                </div>
              </template>
            </b-table>

            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>

            <div class="row">
                <div class="col-12 mt-6">
                  <span class="text-info"><i class="fas fa-info-circle mr-1 text-info"></i> Tips</span>
                  <ul class="ml-6">
                    <li>
                      <span>Alur Status Transaksi : Menunggu Pembayaran <i class="fas fa-arrow-right fa-sm"></i> Terbayar <i class="fas fa-arrow-right fa-sm"></i> Diproses <i class="fas fa-arrow-right fa-sm"></i> Sedang Dikirim <i class="fas fa-arrow-right fa-sm"></i> Selesai</span>
                    </li>
                    <li class="mt-2">
                      <span>Setelah menekan tombol status <span class="text-success">Diproses</span> maka stok obat & alkes akan langsung dikurangi</span>
                    </li>
                    <li class="mt-2">
                      <span>Tombol status Diproses, Sedang Dikirim, dan Selesai digunakan setelah status pembayaran Lunas</span>
                    </li>
                    <li class="mt-2">
                      <span>Tombol status <span class="text-success">Terbayar</span> digunakan untuk konfirmasi manual (Transaksi tidak terkonfirmasi otomatis) </span>
                    </li>
                   
                  </ul>
                </div>
              
              </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // status data
      statusData: "Pending",
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      // Filter
      filter: {
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      dataLoad:false,
      system_online_shop:false,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // data
      productData:[],
      status : false,
      // Fields
      fields: [
        {
          key: "number_display",
          label: "#",
          sortable: true,
        },
        {
          key: "transaction_id",
          label: "Kode",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "product_list",
          label: "Produk(Qty)",
          sortable: false,
          // thStyle:"width: 15%"
        },
        
        {
          key: "total_amount",
          label: "Total",
          sortable: true,
        },
        {
          key: "order_status",
          label: "Pembayaran",
          sortable: true,
          thStyle:"width:10%"
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          thStyle:"width:10%"
        },
        { key: "actions", label: "Aksi", thStyle: "width:20%" },
      ],
      items: [],
      itemEmpty: [],
      manipulateBtn: false,
      usedBtn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      this.status = false
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "order-status",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      
      

      response.data.forEach( async (el,index) => {
        let responseData = await module.get(
          `wp-order-item/${el.order_id}`
        );

        response.data[index].product = responseData.line_items

        if(typeof responseData.billing !== "undefined" && responseData.billing != []){
          response.data[index].customer_name = responseData.billing.first_name +" "+ responseData.billing.last_name 
        }else{
          response.data[index].customer_name = ""
        }

        if(responseData.date_paid == null){
          response.data[index].order_status = "false"
        }else{
          response.data[index].order_status = "true"
        }

        console.log(responseData.status,"========= log ======");
        if(responseData.status == "processing"){
          response.data[index].current_status = "Diproses"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "on-hold"){
          response.data[index].current_status = "Menunggu Pembayaran"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "completed"){
          response.data[index].current_status = "Selesai"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "failed"){
          response.data[index].current_status = "Gagal"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "pending-payment"){
          response.data[index].current_status = "Pembayaran Ditunda"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "cancelled"){
          response.data[index].current_status = "Batal"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "shipping"){
          response.data[index].current_status = "Sedang Dikirim"
          console.log("===== jalan =====",responseData.status);
        }else if(responseData.status == "paid"){
          response.data[index].current_status = "Terbayar"
          console.log("===== jalan =====",responseData.status)
        }

        console.log(responseData,"===== res ====");
        if(index == response.data.length-1){
          this.status = true
        }
      });

      // if(status == true){
        this.items = response.data;
      // }

      
      

      console.log(this.items,"===== items =====");

      // let h 
      // for(h=0;h<this.items.length;h++){
      //   if(this.items[h].current_status == "processing"){
      //     this.items[h]["status"] = "Diproses"
      //   }else if(this.items[h].current_status == "wc-on-hold"){
      //     this.items[h]["status"] = "Menunggu Pembayaran"
      //   }else if(this.items[h].current_status == "wc-completed"){
      //     this.items[h]["status"] = "Selesai"
      //   }else if(this.items[h].current_status == "wc-failed"){
      //     this.items[h]["status"] = "Gagal"
      //   }else if(this.items[h].current_status == "wc-pending-payment"){
      //     this.items[h]["status"] = "Pembayaran Ditunda"
      //   }else if(this.items[h].current_status == "wc-cancelled"){
      //     this.items[h]["status"] = "Batal"
      //   }else if(this.items[h].current_status == "wc-shipping"){
      //     this.items[h]["status"] = "Sedang Dikirim"
      //   }else if(this.items[h].current_status == "wc-paid"){
      //     this.items[h]["status"] = "Terbayar"
      //   }
        
      //   // let dataDetail = await module.get('order-status-ecommerce/'+ this.items[h]["transaction_id"])

      //   // this.items[h]["customer_name"] = dataDetail["customer_name"]
      //   // this.items[h]["product"] = dataDetail["product"]
      //   // this.items[h]["quantity"] = dataDetail["quantity"]
      //   // this.items[h]["price"] = dataDetail["price"]
      //   // this.items[h]["total_amount"] = dataDetail["total_amount"]

      //   // if(h == this.items.length-1){
      //   //   this.dataLoad = true
      //   // }
      // }

      // console.log(this.items[0]["customer_name"])
    },

    async itemData(id) {
      // let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        `wp-order-item/${id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}`
      );
      this.productData = response.data;
    },

    handleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    filterByPatientName() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.dataLoad = false
      this.pagination();
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      this.dataLoad = false
      this.pagination();
    },

    filterByPeriode() {
      this.dataLoad = false
      this.pagination();
    },

    async handleEdit(id,status) {
      // let data = await module.get("get-order-status/" + id);
      // data["_method"] = "put";
      let data = {
        current_status : status,
        _method : "put"
      }
      
      let response = await module.submit(data, "update-status/" + id);
      // Check Response
      if (response.state != "error") {
        // Success
        // this.$root.$emit("UpdateNewAvailableBed");
        Swal.fire("Berhasil", "Berhasil Mengubah Status", "success");
        this.dataLoad = false
        this.pagination();
      }
    },

    async handleDelete(id) {
      let result = await module.delete("used-beds/" + id);
      if (result) {
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

       
        if (access_right[a] == "2602") {
          this.system_online_shop = true
        }
      }
    },

    btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1,'months').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1,'months').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Toko Online" },
      { title: "Daftar Pesanan" },
    ]);
    this.pagination();
    this.setActiveMenu();
  },
};
</script>

<style>
</style>